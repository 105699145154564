<template>
    <div>
        <div v-if="userSession != ''">
            <div class="inner" v-if="carList && carList.length > 0">
                <div class="banner">
                    <img src="../assets/carType-banner.png" width="100%" />
                    <div class="news-banner">
                        <div class="news-banner-search over pt20 pb20">
                            <el-select v-model="selectValue" filterable
                                       remote
                                       reserve-keyword placeholder="请输入搜索关键词/配置编号" :remote-method="getSearchKey" @change="handleDetails">
                                <el-option v-for="item in searchOptions" :value="item.id" :label="item.name" :key="item.value">
                                </el-option>
                            </el-select>
                            <i class="el-icon-search fr ft30"></i>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="mt50 pt50 mb50 pt50">
                        <carFilterBar :series.sync="queryCarParams.series" :countryCode.sync="queryCarParams.countryCode" @refreshDataList="getCarList"></carFilterBar>
                    </div>
                    <el-row :gutter="40">
                        <el-col :span="8" v-for="(item,index) in carList" :key="index">
                            <div class="list-img">
                                <div class="outer-car hover tr3" @click="handleCarDetails(item.id)">
                                    <div class="tag ft18">编号：{{item.code}}</div>
                                    <el-image :src="item.mainPic" style="width: 100%;height: 100%;display: block;" fit="cover"></el-image>
                                </div>
                                <div class="ft18 pt20 pb30 l16 line2 title" style="height: 40px;">{{item.name}}</div>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="tc pt50 pb50 mb50">
                        <!--<el-pagination background layout="prev, pager, next" :total="11"  :current-page="queryCarParams.pageNum" :page-size="queryCarParams.pageSize"  @current-change="handleCurrentChange">-->
                        <!--</el-pagination>-->
                        <el-pagination
                                background layout="prev, pager, next"
                                :current-page="queryCarParams.pageNum" :page-size="queryCarParams.pageSize"  @current-change="handleCurrentChange"
                                :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>

            <div v-else style="height: calc(100vh - 205px)">
                <el-empty :image-size="200" description="暂无VIP车型"></el-empty>
            </div>
        </div>
    </div>
</template>

<script>
  import { getByUnionId, pageCarListByVip } from '@/api/index.js'
  export default {
    data(){
      return{
        userSession: sessionStorage.getItem("userSession") || '',

        // 查询参数
        queryCarParams: {
          code: '',
          name: '',
          keyword:'', // 关键词
          category: '',
          series: '',
          countryCode: [],
          pageNum: 1,
          pageSize: 9
        },
        queryParams: {
          keyword:'', // 关键词
          pageNum: 1,
          pageSize: 1000
        },
        carList: [],
        total: 0,

        selectOptions: [],
        searchOptions: [],
        selectValue: '',
      }
    },
    created() {
      if(this.userSession == ''){
        this.pcLogin()
      }else{
        this.queryCarParams.category = this.$route.query.category || ''
        // 获取用户信息
        this.getUserInfo()
      }
    },
    methods:{
      pcLogin(){
        location.href = 'https://www.tjxinhaida.com/api/pcLogin/qrAuthorize'
      },
      getUserInfo(){
        getByUnionId(this.userSession).then(res=>{
          if(res.code === 200){
            sessionStorage.setItem('userType', res.data.type)
          }
        }).then(() => {
          if(sessionStorage.getItem('userType') && sessionStorage.getItem('userType') != '' && sessionStorage.getItem('userType') == '2'){
            this.getCarList()
            this.getSearchList()
          }
        })
      },


      /**  获取汽车基础信息列表 **/
      getCarList () {
        pageCarListByVip(this.queryCarParams).then(res => {
          if (res.code === 200) {
            this.carList = res.data.list;
            this.total = res.data.count;
          }
        })
      },
      handleCurrentChange(val){
        this.queryCarParams.pageNum = val
        this.getCarList()
      },
      handleCarDetails(id){
        this.$router.push({
          path: '/carType-details',
          query:{
            id: id
          }
        })
      },
      /***  获取搜索列表 *****/
      getSearchList(){
        this.queryParams.keyword = this.selectValue
        pageCarListByVip(this.queryParams).then(res => {
          if (res.code === 200) {
            this.selectOptions = res.data.list;
          }
        })
      },

      /** 获取搜索信息  ***/
      getSearchKey(query){
        if (query !== '') {
          setTimeout(() => {
            this.searchOptions = this.selectOptions.filter(item => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1 || item.code.toLowerCase().indexOf(query.toLowerCase()) > -1;
            });
          }, 200);
        } else {
          this.searchOptions = [];
        }
      },

      /** 跳转车型详情信息 **/
      handleDetails(){
        this.$router.push({
          path: '/carType-details',
          query:{
            id: this.selectValue
          }
        })
      },

    }

  };
</script>

<style scoped="scoped">

    .banner .news-banner {
        position: absolute;
        top: 35%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -35%);
    }

    .banner .banner-text {
        position: absolute;
        bottom: 0;
        z-index: 2;
        width: 100%;
        box-sizing: border-box;
        padding: 0 100px 60px;
    }

    .banner .news-banner-search {
        background-color: rgba(255, 255, 255, 0.8);
        width: 690px;
        font-size: 18px;
        position: relative;
    }

    .banner .news-banner-search .el-icon-search {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
    }

    .banner .news-banner-search .el-select {
        width: 100%;
    }

    .banner .news-banner-search .el-select /deep/ .el-input__inner {
        background-color: transparent;
        font-size: 18px;
        border: none;
        font-weight: normal;
        padding: 0 30px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 60px;
    }
    .banner .news-banner-search .el-select /deep/ .el-input__inner::-moz-placeholder{color: #666;}
    .banner .news-banner-search .el-select /deep/ .el-input__inner::-ms-input-placeholder{color: #666;}
    .banner .news-banner-search .el-select /deep/ .el-input__inner::-webkit-input-placeholder{color: #666;}
    .banner .news-banner-search .el-select /deep/ .el-input__suffix {
        display: none;
    }

    .el-select-dropdown .el-select-dropdown__item {
        font-size: 16px;
        padding: 10px 15px;
        height: auto;
        width: 100%;
    }
    .list-img .outer-car{
        height: 370px;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }
    .list-img .outer-car .tag{
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        padding:10px 20px;
        font-size: 14px;
        color: #FFFFFF;
        background-color: rgba(0,0,0,.5);
    }
</style>
